import React, { useState, useRef }  from 'react';
import Navbar from '../components/NavBar';
import SecondaryPageHeader from '../components/SecondaryPageHeader';
import Footer from '../components/Footer';
import PhoneInput from 'react-phone-input-2';
import { GrUserExpert } from "react-icons/gr";
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { BsCashCoin } from "react-icons/bs";
import emailjs from '@emailjs/browser';

export const Sell = () => {

  const [showContactForm, setShowContactForm] = useState(false);
  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm('service_d1zwo05', 'template_89wjwah', form.current, {
        publicKey: '49bhJ2njVCtaqNXwN',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }

  return (
    <>
      <div className='header-section'>
        <Navbar />
        <SecondaryPageHeader pageTitle="Sell" pageDesc="Home / Sell" imgURL="/secondary-page-bg.png" />
      </div>
      <div className='container m-auto contact-us-card contact-us my-4'>
        <div className='w-70 m-auto p-4 text-center'>
          {/* <h3 className='primary-text'>Want To Sell Your Property With Us?</h3>
          <p className='secondary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolor quisquam inventore consequuntur officiis eius! In minima veniam voluptas, quidem aperiam quibusdam id nesciunt excepturi expedita a provident obcaecati pariatur neque omnis, voluptatum vero! Placeat consequuntur reiciendis tempora ipsum non!</p> */}
          <div className='sell-form-container'>
            <div className='pt-3 row mb-4'>
              <h4 className='primary-text text-center'>Share Your Details</h4>
              <p className='secondary-text'>Our Best Agent will get back to help you!</p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div user-name-field'>
                {/* <label className='col-form-label'>Name</label> */}
                <input className='form-control' type="text" name="user_name" placeholder='Contact Name' required/>
              </div>
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div email-field'>
                {/* <label className='col-form-label'>Email</label> */}
                <input className='form-control required' type="email" name="user_email" placeholder='Email Address' required />
              </div>
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div'>
                {/* <label className='col-form-label'>Contact Number</label> */}
                {/* <input className='form-control' type="number" name="phone_number" required /> */}
                <PhoneInput
                  country={'ae'}
                  containerClass='contact-us-div'
                  inputProps={{'name': 'phone_number'}}
                  enableSearch={true}
                />
              </div>
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div property-location'>
                {/* <label className='col-form-label'>Contact Number</label> */}
                {/* <input className='form-control' type="number" name="phone_number" required /> */}
                <input className='form-control required' type="text" name="property_title" placeholder='Property Name' />
              </div>
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div property-location'>
                {/* <label className='col-form-label'>Contact Number</label> */}
                {/* <input className='form-control' type="number" name="phone_number" required /> */}
                <input className='form-control required' type="text" name="property_city" placeholder='Property City' />
              </div>
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div property-country'>
                {/* <label className='col-form-label'>Contact Number</label> */}
                {/* <input className='form-control' type="number" name="phone_number" required /> */}
                <input className='form-control required' type="text" name="property_country" placeholder='Property Country' />
              </div>
              {/* <div className='col-lg-12 mb-3 position-relative contact-us-input-div'>
                <label className='col-form-label'>Property ID</label>
                <input type="file" id="pdfFile" className='form-control' name="pdfFile" accept="application/pdf" placeholder='Attach Property PDF File' />
                <input className='form-control' type="text" name="property_id" placeholder='' />
              </div> */}
              <div className='col-lg-12 mb-3 position-relative contact-us-input-div'>
                {/* <label className='col-form-label'>Message</label> */}
                <textarea className='form-control' name="message" placeholder='How can we help you?' />
              </div>
              <input className='btn contact-us-submit w-100 fw-bold mb-2' type="submit" value="SUBMIT DETAILS" />
              <p className='sm-font text-center'>By Clicking Submit, you agree to our <a href='#' target='blank'>Terms and Conditions</a> and <a href='#' target='blank'>Privacy Policy</a>.</p>
            </form>
          </div>  
        </div>
      </div>
      <div className='container who-we-are-content mt-5'>
        <h2 className='text-center py-3 fw-bold primary-text'>WHY US?</h2>
        <h2 className='text-center pb-3 fw-bold mb-3'>Unlock the True Worth of Your Property Today</h2>
        <div className='who-we-are who-we-are-sell d-flex justify-content-around align-items-center my-5'>
          <div className='details-card-item'>
            {/* <img src='/bed.png' alt='' className='details-card-item-icons' /> */}
            <GrUserExpert className='details-card-item-icons' />
            <div className='p-4 d-flex flex-column'>
              <span className='l-font primary-text text-center'>Experienced Experts</span>
              {/* <div className='xl-font primary-text'>10+</div> */}
              <span className="text-center">Showcase the decades of experience your team has in the market.</span>
            </div>
          </div>
          <div className='details-card-item'>
            {/* <img src='/bed.png' alt='' className='details-card-item-icons' /> */}
            <LiaGlobeAmericasSolid className='details-card-item-icons' />
            <div className='p-4 d-flex flex-column'>
              <span className='l-font primary-text text-center'>Global Reach</span>
              {/* <div className='xl-font primary-text'>20+</div> */}
              <span className="text-center">Mention your operations in 13+ countries to assure users of wide market exposure.</span>
            </div>
          </div>
          <div className='details-card-item'>
            {/* <img src='/bed.png' alt='' className='details-card-item-icons' /> */}
            <BsCashCoin className='details-card-item-icons' />
            <div className='p-4 d-flex flex-column'>
              <span className='l-font primary-text text-center'>Maximized Returns</span>
              {/* <div className='xl-font primary-text'>{count}+</div> */}
              <span className="text-center">Emphasize the promise of obtaining the highest possible value for their property.</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}  

export default Sell;