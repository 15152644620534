import React from "react";
import { FaMeta } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

const Footer = () => {
  const domain = window.location.hostname;
  const isUAEDomain = domain === 'www.cfsip.ae';
  function uaeAddress() {
    return (<>
      <div className="footer-column footer-column-3 col-12 col-sm-6 col-lg-6">
        <div id="text-6" className="woodmart-widget widget footer-widget  widget_text mb-4">
          <h5 className="widget-title">Registered Office</h5>			
          <div className="textwidget"><div style={{lineHeight: 2, color: 'white'}}><i className="fa fa-location-arrow" style={{width: '15px', textAlign: 'center', marginRight: '4px', color: 'white'}}></i>CAPITAL FINSERV INTERNATIONAL PROPERTY L.L.C,<br></br> Office n0.1008, 10th Floor, Concord Tower, Dubai Media City, Dubai - UAE</div></div>
        </div>					
      </div>
    </>)
  }
  function commonAddress() {
    return (<>
      <div className="footer-column footer-column-3 col-12 col-sm-6 col-lg-3">
        <div id="text-6" className="woodmart-widget widget footer-widget  widget_text mb-4">
          <h5 className="widget-title">Head Office</h5>			
          <div className="textwidget"><div style={{lineHeight: 2, color: 'white'}}><i className="fa fa-location-arrow" style={{width: '15px', textAlign: 'center', marginRight: '4px', color: 'white'}}></i> &nbsp;Unit no.3, 1st Floor | St Mary At Hill | London | EC3R 8EE | United Kingdom</div></div>
        </div>
        <div id="text-3" className="woodmart-widget widget footer-widget  widget_text mb-4">
          <h5 className="widget-title">Registered Office</h5>			
          <div className="textwidget">
            <div style={{lineHeight: 2, color: 'white'}}>
              <p><i className="fa fa-location-arrow" style={{width: '15px', textAlign: 'center', marginRight: '4px', color: 'white'}}></i>P.O.Box.3111 | Grand Pavilion | West Bay Road | Grand Cayman | Cayman Islands</p>
            </div>
            <hr />
            <div style={{lineHeight: 2, color: 'white'}}>
              <p><i className="fa fa-location-arrow" style={{width: '15px', textAlign: 'center', marginRight: '4px', color: 'white'}}></i><b>Africa Office: </b>Capital Finserv International, 7th Floor, 9 West Building, Kofisi 9, Westlands, Nairobi, Kenya</p>
            </div>
            <p>&nbsp;</p>
          </div>
        </div>						
      </div>
    </>)
  }
  return (
    <div className='footer-section d-flex justify-content-evenly align-items-center p-5 gap-4'>
      <div className="container main-footer">
		    <aside className="footer-sidebar widget-area row" role="complementary">
					<div className="footer-column footer-column-1 col-12 col-sm-6 col-lg-3">
						<div id="media_image-3" className="woodmart-widget widget footer-widget  widget_media_image">
              <img width="300" height="58" src="https://cfsi.ae/wp-content/uploads/2022/12/Color-logo-no-background1-300x58.png" className="image wp-image-395  attachment-medium size-medium" alt="" style={{maxWidth: '100%', height: 'auto'}} decoding="async" loading="lazy" srcset="https://cfsi.ae/wp-content/uploads/2022/12/Color-logo-no-background1-300x58.png 300w, https://cfsi.ae/wp-content/uploads/2022/12/Color-logo-no-background1-1024x197.png 1024w, https://cfsi.ae/wp-content/uploads/2022/12/Color-logo-no-background1-768x147.png 768w, https://cfsi.ae/wp-content/uploads/2022/12/Color-logo-no-background1-1536x295.png 1536w, https://cfsi.ae/wp-content/uploads/2022/12/Color-logo-no-background1-2048x393.png 2048w" sizes="(max-width: 300px) 100vw, 300px" />
            </div>
            <hr></hr>
            <div id="text-2" className="woodmart-widget widget footer-widget  widget_text">
              <h5 className="widget-title mb-0">Follow US</h5>
              <div className="textwidget">
                <div className="d-flex mb-4 text-left icons-design-colored icons-size-default color-scheme-light social-follow">
                  <a className="woodmart-social-icon social-facebook" href="https://www.facebook.com/CFSICAYMAN?mibextid=ZbWKwL" target="_blank" rel="nofollow noopener"><br />
                    <FaMeta className="social-icons me-3" /><br />
                    {/* <span className="woodmart-social-icon-name">Facebook</span><br /> */}
                  </a>
                  <a className=" woodmart-social-icon social-instagram" href="https://instagram.com/capitalfinservinternational?igshid=YmMyMTA2M2Y=" target="_blank" rel="nofollow noopener"><br />
                    <FaInstagram className="social-icons me-3"  /><br />
                    {/* <span className="woodmart-social-icon-name">Instagram</span><br /> */}
                  </a>
                  <a className=" woodmart-social-icon social-linkedin" href="https://www.linkedin.com/company/capital-finserv-international/" target="_blank" rel="nofollow noopener"><br />
                    <FaLinkedinIn className="social-icons" /><br />
                    {/* <span className="woodmart-social-icon-name">linkedin</span><br /> */}
                  </a>
                </div>
              </div>
          </div>						
          </div>
					<div className="footer-column mb-4 footer-column-2 col-12 col-sm-6 col-lg-3">
						<div id="nav_menu-2" className="woodmart-widget widget footer-widget  widget_nav_menu">
              <h5 className="widget-title">Quick links</h5>
              <div className="menu-quick-links-container">
                <ul id="menu-quick-links" className="menu">
                  {/* <li id="menu-item-518" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-518"><a href="https://cfsi.ae/property-in-dubai/">Properties in Dubai</a></li>
                  <li id="menu-item-612" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-612"><a href="https://cfsi.ae/property-in-istanbul/">Properties in Istanbul</a></li>
                  <li id="menu-item-849" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-849"><a href="https://cfsi.ae/property-in-uk/">Property In UK</a></li>
                  <li id="menu-item-901" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-901"><a href="https://cfsi.ae/property-in-phuket/">Property in Phuket</a></li>
                  <li id="menu-item-965" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-965"><a href="https://cfsi.ae/property-in-batumi/">Property In Batumi</a></li>
                  <li id="menu-item-48" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-48"><a href="https://cfsi.ae/services/">Services</a></li> */}
                  {/* <li id="menu-item-603" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-603"><a href="https://cfsi.ae/events/">Events</a></li>
                  <li id="menu-item-648" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-648"><a href="https://cfsi.ae/blog/">Blog</a></li> */}
                  {!isUAEDomain && <li id="menu-item-50" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50"><a href="https://cfsi.ae/">More on Offshore investment & Wealth Management</a></li>}
                  <li id="menu-item-50" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50"><a href="/buy/">Buy</a></li>
                  <li id="menu-item-50" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50"><a href="/sell/">Sell</a></li>
                  <li id="menu-item-50" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50"><a href="/about-us/">About Us</a></li>
                  <li id="menu-item-49" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-49"><a href="/contact-us/">Contact  us</a></li>
                  {/* <li id="menu-item-696" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-696"><a href="https://cfsi.ae/privacy-policy/">Privacy Policy</a></li> */}
                </ul>
              </div>
            </div>						
          </div>
				 {isUAEDomain ? uaeAddress() : commonAddress()}
         {
          !isUAEDomain &&
          <div className="footer-column footer-column-4 col-12 col-sm-6 col-lg-3 mb-4">
            <div id="text-7" className="woodmart-widget widget footer-widget  widget_text">
              <h5 className="widget-title">Representatives in</h5>			
              <div className="textwidget">
                <div style={{lineHeight: 2, color: 'white'}}>
                  <i className="fa fa-location-arrow" style={{width: '15px', textAlign: 'center', marginRight: '4px', color: 'white'}}></i> Dubai | USA | Thailand | Uganda | Rwanda | Tanzania | Singapore | Kenya | Ghana | India | Canada | France | Switzerland | USA | Monaco | Hong Kong
                </div>
                <p>&nbsp;</p>
              </div>
            </div>						
          </div>
         }
				</aside>
	    </div>
    </div>
  )
};

export default Footer;