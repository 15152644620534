import React, { useState, useRef }  from 'react';
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const ContactUsForm = ({showContactForm, setShowContactForm, propertyTitle, propertyCountry}) => {
//   const [show, setShow] = useState(true);

  const handleClose = () => setShowContactForm(false);
  const handleShow = () => setShowContactForm(true);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_d1zwo05', 'template_7i8g9k5', form.current, {
        publicKey: '49bhJ2njVCtaqNXwN',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <Modal
      show={showContactForm}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='contact-us'
      onHide={handleClose}
    >
      <Modal.Body className='contact-us-modal'>
        <div className='pt-3 row mb-4'>
          <h4 className='col-lg-11 d-flex align-items-center m-0'>General Enquiry</h4>
          <button type="button" onClick={handleClose} class="col-lg-1 btn-close btn-close-white d-flex align-items-center" aria-label="Close"></button>
          {/* <span onClick={closeModal} className='col-lg-1 text-muted float-end close-button cursor-pointer'>x</span> */}
        </div>
        <hr />
        <form ref={form} onSubmit={sendEmail}>
          <div className='col-lg-12 mb-3 position-relative contact-us-input-div user-name-field'>
            {/* <label className='col-form-label'>Name</label> */}
            <input className='form-control' type="text" name="user_name" placeholder='Contact Name' required/>
          </div>
          <div className='col-lg-12 mb-3 position-relative contact-us-input-div email-field'>
            {/* <label className='col-form-label'>Email</label> */}
            <input className='form-control required' type="email" name="user_email" placeholder='Email Address' required />
          </div>
          <div className='col-lg-12 mb-3 position-relative contact-us-input-div'>
            {/* <label className='col-form-label'>Contact Number</label> */}
            {/* <input className='form-control' type="number" name="phone_number" required /> */}
            <PhoneInput
              country={'ae'}
              containerClass='contact-us-div'
              inputProps={{'name': 'phone_number'}}
              enableSearch={true}
            />
          </div>
          <div className='col-lg-12 mb-3 position-relative contact-us-input-div visually-hidden'>
            {/* <label className='col-form-label'>Property ID</label> */}
            <input className='form-control' type="text" name="property_title" value={propertyTitle} />
          </div>
          <div className='col-lg-12 mb-3 position-relative contact-us-input-div visually-hidden'>
            {/* <label className='col-form-label'>Country</label> */}
            <input className='form-control' type="text" name="country" value={propertyCountry} />
          </div>
          <div className='col-lg-12 mb-3 position-relative contact-us-input-div'>
            {/* <label className='col-form-label'>Message</label> */}
            <textarea className='form-control' name="message" placeholder='How can we help you?' />
          </div>
          <input className='btn contact-us-submit w-100 fw-bold mb-2' type="submit" value="SUBMIT DETAILS" />
          <p className='sm-font text-center'>By Clicking Submit, you agree to our <a href='#' target='blank'>Terms and Conditions</a> and <a href='#' target='blank'>Privacy Policy</a>.</p>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default ContactUsForm;