import React, { useState, useRef }  from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import SearchForm from '../components/SearchForm';
import PropertyList from '../components/PropertiesList';
import PropertiesByCountry from '../components/PropertiesByCountry';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaLocationPin } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {coutriesList} from '../utils/constants';
import SecondaryPageHeader from '../components/SecondaryPageHeader';
import { Link } from 'react-router-dom';

function Buy() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let paramCountryCode = params.get('countryCode') || '';
  let countryName = coutriesList.find((country) => country.countryCode === paramCountryCode)?.countyName;
  const targetRef = useRef(null);
  const domain = window.location.hostname;
  const isUAEDomain = domain === 'www.cfsip.ae'


  const [properties, setProperties] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setLoadingState] = useState(false);

  function setPageNumber(event, value) {
    setpageNumber(value);
    scrollToDiv();
  }
  const scrollToDiv = () => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <>
      <div className='header-section'>
        <Navbar />
        <SecondaryPageHeader pageTitle="Buy" pageDesc="Home / Buy" imgURL="/secondary-page-bg.png" />
      </div>  
      <div ref={targetRef} className='container'>
        <div className='my-3 d-flex justify-content-center align-items-center'>
          {/* <button className='btn btn-primary mx-3'>Buy</button>
          <button className='btn btn-secondary mx-2'>Rent</button> */}
          <SearchForm countryCode={paramCountryCode} isUAEDomain={isUAEDomain} countryName={countryName} setProperties={setProperties} pageNumber={pageNumber} setTotalResults={setTotalResults} setPageCount={setPageCount} coutriesList={coutriesList} setLoadingState={setLoadingState} />
        </div>
        <hr />
        <div className="buy-page-results-header d-flex justify-content-between align-items-center mb-4">
          {isLoading ?
          <div className="loading-comp-txt"></div>
          : <div className='text-muted'>{totalResults} Matching Results</div>}
          {/* <h1 className='text-center'>Properties For Sale <span className='primary-text'>{countryName && `in ${countryName}`}</span></h1> */}
          <div></div>
        </div>
        <PropertyList properties={properties} isLoading={isLoading} />
        <div className='d-flex justify-content-center my-4'>
          <Stack spacing={2}>
            <Pagination count={pageCount} onChange={setPageNumber} variant="outlined" shape="rounded" />
          </Stack>
        </div>
      </div>
      <hr></hr>
      {/* why choose us */}
      <div className='container pt-4 section-seperator'>
        <div className='d-flex justify-content-evenly align-items-center mt-5 list-property'>
          <div className='list-property-img'>
            <img src='/list-property-img.png' alt='' />
          </div>
          <div className='list-property-desc text-center w-50 mt-4'>
            <h1 className='primary-text'>Unlock the Potential <br />of Your Property with <br />CFSI</h1>
            <div className='py-2 px-5'>
              We manages the UAE's largest portfolio of private and commercial properties with over 8,500 units under management. With a team of over 50 property management professionals, first-rate marketing and best-in-class technology, we take care of everything.
              <br /><br />Join our exclusive community of property owners and showcase your listing to a global audience.
            </div>
            <Link className='btn btn-primary p-3 my-4' to="/sell">List Your Property</Link>
            {/* <button className='btn btn-primary p-3 my-4'>List Your Property</button> */}
          </div>
        </div>
      </div>
      <Footer />
    </>    
  )
}

export default Buy;